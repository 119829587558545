<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data 
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        :total="dTotal"
                        @keyPress="keyPress"
                    />
                    <forms-save-btn
                        :progress="progress"
                        :isLoad="isLoad"
                        :variantAttribute="variantAttribute"
                        @prepareForSave="prepareForSave"
                    ></forms-save-btn>
                </div>
            </div>
        </div>

        <breadcrumbs-filter 
            :header="{...header, formName: this.formName, spfName: this.spfName}" 
            :data-type-filter="dataTypeFilter"
            :variant-name="variantName" 
            @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering text-center"></td>
                    <td class="td-numbering table-success text-center">1</td>
                    <td class="td-numbering table-danger text-center">2</td>
                    <td class="td-numbering table-info text-center">3</td>
                    <td class="td-numbering table-primary text-center">4</td>
                    <td class="td-numbering table-warning text-center">5</td>
                    <td class="td-numbering table-success text-center">6</td>
                    <td class="td-numbering table-danger text-center">7</td>
                    <td class="td-numbering table-primary text-center">8</td>
                    <td class="td-numbering table-info text-center">9</td>
                    <td class="td-numbering table-warning text-center">10</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="selectAll" 
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_selected')"
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${selectAll ? getDecText('del_all_recs') : getDecText('del_selected_recs')}`)"/>
                    </div>
                </template>
                <template #cell(action)="data">
                    <template v-if="variantAttribute">
                        <b-button v-if="data.item.src_group" :title="getFormText('add_vehicle')" style="color:green" @click="addItem(data.item, data.index)"><span style='font-size:1.4rem; color: rgb(124 157 179 / 75%); font-weight:normal;'>+</span></b-button>
                        <b-form-checkbox 
                            v-if="!data.item.src_group"
                            v-model="data.item.itemToDelete"
                            @input="e => { if (!e) selectAll = false; }"
                            :value="true"
                            :unchecked-value="false" />
                    </template>
                    <template v-else>
                        <div>{{ data.value }}</div>
                    </template>
                </template>
                <template #cell(name_ru)="data">
                    <div v-if="data.item.src_group" class="text-left parent-cat">{{ data.item[`name_${lng}`] }}</div>
                    <div 
                        v-else                         
                        :class="{ 'error': data.item.isDouble }">
                        <b-form-select v-if="variantAttribute"
                                       size="sm"
                                       class="w-200px child-cat"
                                       v-model="data.item.model"
                                       :options="dict"
                                       @change="changeModel(data.item, data.item.model)"
                        >
                        </b-form-select>
                        <div v-else class="child-cat">{{ getModelName(data.item.model) }}</div>
                        <template v-if="data.item.isDouble">
                            <i 
                                class="icon icon-danger"
                                @mouseover="data.item.showDoubleMsg = true"
                                @mouseleave="data.item.showDoubleMsg = false"></i>
                            <div v-show="data.item.showDoubleMsg" class="pop-up">
                                <p class="red-text">{{ getCommonText('not_valid') }} </p>
                                <p>{{ getCommonText('exist') }}</p>
                            </div>
                        </template>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.name_ru === null || data.item.name_ru === '')">{{ getCommonText("must_be_filled") }}</p>
                    </div>
                </template>
                <template #cell(amount_standard)="data">
                    <b-form-input v-if="!data.item.src_group && variantAttribute"
                                  class="text-right"
                                  :value="data.item.amount_standard"
                                  @change="v => data.item.amount_standard = v"
                                  @keyup.enter.exact="(e) => { inputFixed(data.item, 'cost_unit', data.item.cost_unit, 2); keyup13(e); }"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'amount_standard', data.item.amount_standard, 2)">
                    </b-form-input>
                    <div v-else-if="!data.item.src_group && !variantAttribute" class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.amount_standard === null || data.item.amount_standard === '' || data.item.amount_standard <= 0)">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(balance)="data">
                    <b-form-input v-if="!data.item.src_group && variantAttribute"
                                  class="text-right"
                                  :value="data.item.balance"
                                  @change="v => data.item.balance = v"
                                  @keyup.enter.exact="(e) => { inputFixed(data.item, 'cost_unit', data.item.cost_unit, 2); keyup13(e); }"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'balance', data.item.balance, 2)">
                    </b-form-input>
                    <div v-else-if="!data.item.src_group && !variantAttribute" class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(rent)="data">
                    <b-form-input v-if="!data.item.src_group && variantAttribute"
                                  class="text-right"
                                  :value="data.item.rent"
                                  @change="v => data.item.rent = v"
                                  @keyup.enter.exact="(e) => { inputFixed(data.item, 'cost_unit', data.item.cost_unit, 2); keyup13(e); }"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'rent', data.item.rent, 2)">
                    </b-form-input>
                    <div v-else-if="!data.item.src_group && !variantAttribute" class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(year_exit)="data">
                    <b-form-input v-if="!data.item.src_group && variantAttribute"
                                  :disabled="isFieldDisabled(data.item)"
                                  class="text-right"
                                  :value="data.item.year_exit"
                                  @change="v => { data.item.year_exit = parseInt(v) }"
                                  @keyup.enter.exact="(e) => { inputFixed(data.item, 'cost_unit', data.item.cost_unit, 2); keyup13(e); }"
                                  @keypress="keyPress($event, '^[0-9]+$', 'year_exit', data.item)"
                                  @blur="inputFixed(data.item, 'year_exit', data.item.year_exit, 2)">
                    </b-form-input>
                    <div v-else-if="!data.item.src_group && !variantAttribute" class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(wear)="data">
                    <b-form-input v-if="!data.item.src_group && variantAttribute"
                                  :disabled="isFieldDisabled(data.item)"
                                  class="text-right"
                                  :value="data.item.wear"
                                  @change="v => { data.item.wear = parseInt(v) }"
                                  @keyup.enter.exact="(e) => { inputFixed(data.item, 'cost_unit', data.item.cost_unit, 2); keyup13(e); }"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'wear', data.item.wear, 2)">
                    </b-form-input>
                    <div v-else-if="!data.item.src_group && !variantAttribute" class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(cost_budget)="data">
                    <b-form-input v-if="!data.item.src_group && variantAttribute"
                                  class="text-right"
                                  :value="data.item.cost_budget"
                                  @change="v => data.item.cost_budget = v"
                                  @keyup.enter.exact="(e) => { inputFixed(data.item, 'cost_unit', data.item.cost_unit, 2); keyup13(e); }"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'cost_budget', data.item.cost_budget, 2)">
                    </b-form-input>
                    <div v-else-if="!data.item.src_group && !variantAttribute" class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(amount_plan)="data">
                    <b-form-input v-if="!data.item.src_group && variantAttribute"
                                  class="text-right"
                                  :value="data.item.amount_plan"
                                  @change="v => data.item.amount_plan = v"
                                  @keyup.enter.exact="(e) => { inputFixed(data.item, 'cost_unit', data.item.cost_unit, 2); keyup13(e); }"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'amount_plan', data.item.amount_plan, 2)">
                    </b-form-input>
                    <div v-else-if="!data.item.src_group && !variantAttribute" class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.amount_plan === null || data.item.amount_plan === '' || data.item.amount_plan <= 0)">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(cost_unit)="data">
                    <b-form-input v-if="!data.item.src_group && variantAttribute"
                                  class="text-right"
                                  :value="data.item.cost_unit"
                                  @change="v => data.item.cost_unit = v"
                                  @keyup.enter.exact="(e) => { inputFixed(data.item, 'cost_unit', data.item.cost_unit, 2); keyup13(e); }"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'cost_unit', data.item.cost_unit, 2)">
                    </b-form-input>
                    <div v-else-if="!data.item.src_group && !variantAttribute" class="text-right">{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.cost_unit === null || data.item.cost_unit === '' || data.item.cost_unit <= 0)">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell()="data">
                    <div class="text-right" :class="{'parent-cat': data.item.src_group}">{{ $n(data.value) }}</div>
                </template>
                <template #cell(more)="data">
                    <i class="icon icon-clear table-remove" v-if="!data.item.src_group" :title="getCommonText('del_rec')" @click="deleteItem(`${getCommonText('clear_rec')}`, data.item, data.index)"/>
                </template>

                <template #bottom-row="data">
                    <td class="text-right" colspan="10">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td></td>
                </template>
            </b-table>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute" :load="load" @getFiles="getFiles"></files-updown>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import FormsSaveBtn from "./components/forms-save-btn.vue";
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';

export default {
    name: 'Form01_413',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        BreadcrumbsFilter,
        FormsSaveBtn, 
        FormsDownloadReprt,
        CBudgetFormsCopyData
    },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '01-413',
                name_ru: 'Расчет расходов на приобретение автомобильных транспортных средств',
                name_kk: 'Автомобиль көлiк құралдарын сатып алуға жұмсалатын шығыстарды есептеу'
            },
            selectedTree: [],
            dict: [],
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false
        };
    },
    async mounted() {
        await this.loadTree();
        await this.loadDict();
    },

    methods: {
        addItem(parent, index) {
            const that = this;
            const item = { id: new Date() * -1 };
            this.itemUpdate(item);
            this.$set(item, 'group', parent);
            this.$set(item, 'showDoubleMsg', false);
            this.$set(item, 'itemToDelete', false);

            Object.defineProperty(item, 'total', {
                get: function () {
                    const total = that.isDigit(item.amount_plan) * that.isDigit(item.cost_unit) / 1000;
                    return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                }
            });

            this.budgetForm.splice(index + 1, 0, item);
        },

        isFieldDisabled(item) {
            return !((item.rent) || (item.balance))
        },

        changeModel(item, model) {
            this.$set(item, 'model', model);
            this.checkForDoubles(item);
        },

        createTable(elem, parent) {
            const that = this;

            const item = Object.assign({}, elem);
            that.$set(item, 'group', parent);
            that.$set(item, 'visible', true);
            that.$set(item, 'isDouble', false);
            that.$set(item, 'showDoubleMsg', false);
            that.$set(item, 'isNewRow', false);
            that.$set(item, 'itemToDelete', false);
            if (item.src_group) {
                that.$set(item, 'name_ru', item.name_ru);
                that.$set(item, 'open', true);
                Object.defineProperty(item, 'total', {
                    get: function () {
                        let sum = 0;
                        for (const row of that.budgetForm) {
                            if (!row.src_group && (item.code === row.group.code)) {
                                sum += parseFloat(row.total);
                            }
                        }
                        return sum;
                    }
                });
            } else {
                Object.defineProperty(item, 'total', {
                    get: function () {
                        const total = that.isDigit(item.amount_plan) * that.isDigit(item.cost_unit) / 1000;
                        return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                    }
                });
            }
            that.$set(item, 'index', that.budgetForm.length);
            that.$set(that.budgetForm, that.budgetForm.length, item);

            if (item.src_group) {
                for (const ch of item.child) {
                    that.createTable(ch, item);
                }
                delete item.child;
            }
        }, // древовидную выборку преобразовывает в таблицу (для отображения)

        inputFixed(item, field, value, digit) {
            if (!Number(value)) value = null;
            if (value === '' || value === null || value === '0') {
                if (value !== '0') this.$set(item, field, null)
                else this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
                if ((field === 'balance' || field === 'rent') 
                    && (!item.balance) 
                    && (!item.rent)) {
                    this.$set(item, 'wear', null);
                    this.$set(item, 'year_exit', null);
                }
                if (field === 'wear' || field === 'year_exit') this.checkForDoubles(item);
                return;
            }
            this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
            if (field === 'wear' && value > 100) {
                this.$set(item, field, 100);
            }
            
            if (field === 'year_exit') {
                const today = new Date(); 
                const currYear = today.getFullYear()
                if (item.year_exit < currYear - 200) {
                    this.$set(item, field, currYear - 200);
                }
                if (item.year_exit > currYear) {
                    this.$set(item, field, currYear);
                }
            }
            this.checkForDoubles(item); 
        }, // форматирует введенное значение до digit цифр после запятой

        itemUpdate(item) {
            this.$set(item, 'model', null);
            this.$set(item, 'amount_standard', null);
            this.$set(item, 'balance', null);
            this.$set(item, 'rent', null);
            this.$set(item, 'year_exit', null);
            this.$set(item, 'wear', null);
            this.$set(item, 'cost_budget', null);
            this.$set(item, 'amount_plan', null);
            this.$set(item, 'cost_unit', null);
            this.$set(item, 'isDouble', false);
            this.$set(item, 'isNewRow', true);
        },

        isDigit(value) {
            return (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
        },

        getFiles(data) {
            this.files = data;
        }, // список файлов

        getItem(code, list) {
            for (const item of list) {
                if (item.value.code === code) {
                    return item.value;
                }
            }
            return null;
        }, // возвращает объект по коду с заданного списка

        async loadTree() {
            try {
                const response = await fetch('/api-py/dictionary/car_groups/');
                const items = await response.json();
                for (const row of items) {
                    this.$set(row, 'src_group', true);
                    this.$set(row, 'child', []);
                    this.selectedTree.push(row);
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadTree`, error.toString());
            }
        },

        async loadDatas() {
            this.load = true;

            this.selectedTree.forEach(tree => {
                tree.child.splice(0);
            });

            try {
                const response = await fetch('/api-py/get-budget-request-form/' + JSON.stringify(this.header));
                const values = await response.json();

                await values.forEach(val => {
                    const curGroup = this.selectedTree.filter(function (item) {
                        if (item.code === val.code_group) { return item; }
                    });
                    if (curGroup.length > 0) {
                        const group = {
                            code: curGroup[0].code,
                            name_ru: curGroup[0].name_ru
                        };
                        this.$set(val, 'group', group);
                        this.$set(val, 'model', this.getItem(val.code_model, this.dict));
                        curGroup[0].child.push(val);
                    }
                });
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas`, error.toString());
                return;
            }
            this.budgetForm.splice(0);
            for (const item of this.selectedTree) {
                this.createTable(item, null);
            }
            this.load = false;
        },

        async loadDict() {
            try {
                const response = await fetch('/api-py/dictionary/transport_models/');
                if (response.status === 200) {
                    const items = await response.json();
                    const sortedItems = items.sort((prev, next) => prev.model.toLowerCase() > next.model.toLowerCase() ? 1 : -1);
                    for (const row of sortedItems) {
                        const el = {};
                        this.$set(el, 'value', row);
                        const def = this.$i18n.locale === "kk" ? "жоқ" : "нет";
                        Object.defineProperty(el, 'text', {
                            get: () => {
                                return this.getModelName(row);
                            }
                        });
                        this.dict.push(el);
                    }
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDict`, error.toString());
            }
        },

        getModelName(row) {
            const def = this.$i18n.locale === "kk" ? "жоқ" : "нет";
            const engine = row.engine === null ? def : (Math.round((parseFloat(row.engine) / 1000) * 10) / 10).toFixed(1).toString();
            const gearbox = row.gearbox === null ? def : row.gearbox;
            const baseRate = row.base_rate === null ? def : row.base_rate;
            return row.model + ' / ' + engine + ' / ' + gearbox + ' / ' + baseRate
        },

        prepareForSave() {
            const values = [];
            const fieldName = this.tableFields.reduce((acc, curr) => {
                acc[curr.key] = curr.label
                return acc
            }, {})
            const valFields = ['amount_standard', 'amount_plan', 'cost_unit']
            for (const row of this.budgetForm) {
                if (!row.src_group && !row.error) {
                    if (!this.valMoreZero(row, 'name_ru', fieldName, (row) => (row.model === null))) return;
                    if (row.isDouble) {
                        this.makeToast('warning', this.getErrText('msg'), this.getErrText('doubles'));
                        return;
                    }
                    for (const field of valFields) {
                        if (!this.valMoreZero(row, field, fieldName, (row, field) => (row[field] === null || row[field] === '' || row[field] <= 0))) return;
                    }
                    const item = Object.assign({}, this.header);
                    this.$set(item, 'id', row.id);
                    this.$set(item, 'code_group', row.group.code);
                    this.$set(item, 'code_model', row.model.code);
                    this.$set(item, 'amount_standard', parseInt(row.amount_standard));
                    this.$set(item, 'balance', parseInt(row.balance));
                    this.$set(item, 'rent', parseInt(row.rent));
                    this.$set(item, 'year_exit', parseInt(row.year_exit));
                    this.$set(item, 'wear', parseInt(row.wear));
                    this.$set(item, 'cost_budget', parseInt(row.cost_budget));
                    this.$set(item, 'amount_plan', parseInt(row.amount_plan));
                    this.$set(item, 'cost_unit', parseInt(row.cost_unit));

                    values.push(item);
                }
            }
            if (values.length > 0 && this.variantAttribute) {
                this.save({values, header: {...this.header, value: this.total}});
            } else {
                this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
            }
        },

        prepareForDelete(row, index) {
            if (row) {
                this.budgetForm.splice(index, 1);
                let sum = 0;
                for (const row of this.budgetForm) {
                    if (!row.src_group && !row.isNewRow) {
                        sum += parseFloat(row.total);
                    }
                }
                this.$set(this.header, 'value', Math.ceil(sum));
            } else {
                const existingRowsToDel = this.budgetForm.map(item => {
                    if (!item.src_group && item.id >= 0 && item.itemToDelete) return item.id});
                this.budgetForm = this.budgetForm.filter(item => item.src_group || !item.itemToDelete);
                if (!existingRowsToDel.length) { 
                    this.selectAll = false;
                }
                this.deleteSeveralGoods(existingRowsToDel)
            }
            
            this.checkForDoubles(row);
        },

        valMoreZero(row, field = '', fieldName = {}, valFunc) {
            if (valFunc(row, field)) {
                this.makeToast('warning', this.getErrText('not_saved'), this.getDecText('not_valid_field', {fieldName: fieldName[field]}));
                return false;
            }
            return true;
        },

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        checkForDoubles(item = {group: null}) {
            for (const itm1 of this.budgetForm) {
                if (!itm1.group) continue;                
                const doubles = this.budgetForm.filter(itm2 => {
                    return itm1.id !== itm2.id
                        && !!itm2.group 
                        && itm1.group.code === itm2.group.code
                        && ((!itm1.model.code && !itm2.model.code) || (itm1.model === itm2.model))
                        && ((!itm1.year_exit && !itm2.year_exit) || (itm1.year_exit === itm2.year_exit))
                        && ((!itm1.wear && !itm2.wear) || (itm1.wear === itm2.wear))
                })
                if (doubles.length > 0) this.$set(itm1, 'isDouble', true)
                else this.$set(itm1, 'isDouble', false)
            }
        }, // проверка наличия дублей

        afterDeleted() {
            this.checkForDuplicates();
        },
    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                if (!row.src_group) {
                    sum += parseFloat(row.total);
                }
            }
            return Math.ceil(sum);
        },
        dTotal() {
            let sum = 0;
            for (const row of this.budgetForm) {
                if (!row.src_group && row.id > 0) {
                    sum += parseFloat(row.total);
                }
            }
            return Math.ceil(sum);
        },

        getFormText() {
            return this.setFormText('form_01_413.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'name_ru',
                        label: this.getFormText('transport_name')
                    },
                    {
                        key: 'amount_standard',
                        label: this.getFormText('approved_quantity')
                    },
                    {
                        key: 'balance',
                        label: this.getFormText('balance')
                    },
                    {
                        key: 'rent',
                        label: this.getFormText('rent')
                    },
                    {
                        key: 'year_exit',
                        label: this.getFormText('release_year')
                    },
                    {
                        key: 'wear',
                        label: this.getFormText('wearout')
                    },
                    {
                        key: 'cost_budget',
                        label: this.getFormText('budget_sum')
                    },
                    {
                        key: 'amount_plan',
                        label: this.getFormText('planned_transport_count')
                    },
                    {
                        key: 'cost_unit',
                        label: this.getFormText('price_per_unit')
                    },
                    {
                        key: 'total',
                        label: this.getFormText('total_cost')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        },
    }
};
</script>

<style scoped>
.goods-form-val-msg {
    font-size: 0.5rem;
    font-style: italic;
    color: red;
}
.parent-cat {
    font-weight: bold;
}
.child-cat {
    margin-left: 1rem;
}
.error .pop-up, .more-info .pop-up {
    opacity: 1;
    visibility:visible;
}
.error input {
    padding-left: 25px;
}
.filter-actions-flex {
    display: flex;
}
</style>